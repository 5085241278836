import { NextComponentType } from 'next';
import { AppInitialProps, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import React, { useEffect } from 'react';

import '../styles/custom-google-search.scss';

import type { IAbTestingInfo } from '@sravni/ab-testing-sdk/lib/node';
import { ThemeName, ThemeNames } from '@sravni/design-system-theme';
import { IDeviceInfo } from '@sravni/koa-utils/lib/middlewares/device';
import { CommonHead } from '@sravni/react-common-head';
import { Footer } from '@sravni/react-footer';
import { DeviceInfoProvider, ThemeProvider, AbTestingProvider } from '@sravni/react-utils';
import { Interceptor } from '@sravni/ugc/lib/utils/sentry/Interceptor';
import { formatUserData } from '@sravni/utils/lib/auth';

import * as Sentry from '@sentry/browser';

import { AppAnalyticsPixel } from '../components/AppAnalyticsPixel';
import { Header } from '../components/Header';
import Seo from '../components/Seo';
import { TopBanner } from '../components/TopBanner';
import StoreProvider from '../providers/store';
import { getOrCreateStore, IGlobalState } from '../store';
import { setCreditRates } from '../store/reducers/creditRates';
import { setDepositRates } from '../store/reducers/depositRates';
import { setCurrentLocation } from '../store/reducers/locations';
import { setMetadata } from '../store/reducers/metadata';
import { setMortgages } from '../store/reducers/mortgages';
import { setSettings } from '../store/reducers/sitesettings';
import { setUser } from '../store/reducers/user';
import '@sravni/design-system-theme/lib/globalStyles.css';
import styles from '../styles/page.module.scss';

export const AB_NAME = 'AB_HOME';

interface IProps extends AppProps {
  realPath: string;
  initialReduxState: IGlobalState;
  deviceInfo: IDeviceInfo;
  theme: ThemeNames;
  abTestingInfo?: IAbTestingInfo;
}

const MyApp: NextComponentType<App.ReduxAppContext, AppInitialProps, IProps> = (props) => {
  const { Component, pageProps, initialReduxState, deviceInfo, theme, abTestingInfo } = props;
  const reduxStore = getOrCreateStore(initialReduxState);
  const { getState } = reduxStore;
  const { user, sitesettings } = getState();
  const { publicRuntimeConfig } = getConfig();
  const isProduction = publicRuntimeConfig.environment === 'production';
  const userId = user.loggedIn ? user.account?.sub : undefined;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { default: queue } = require('../utils/queue');

    if (isProduction) {
      queue.push(() => {
        Sentry.init({
          dsn: publicRuntimeConfig.sentryDSN,
          release: String(publicRuntimeConfig.release),
          integrations: [Sentry.browserTracingIntegration()],
          tracesSampleRate: 0.2,
          sampleRate: 0.2,
          environment: publicRuntimeConfig.environment,
          denyUrls: [
            /cdn.uxfeedback.ru\//i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
          ],
        });
      });

      queue.push(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/service-worker.js').catch((err) => {
            console.warn('service worker registration failed', err.message);
          });
        }
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Interceptor componentName="home-root-component">
      <StoreProvider store={reduxStore}>
        <AbTestingProvider initialValue={abTestingInfo}>
          <ThemeProvider initialTheme={theme}>
            <DeviceInfoProvider initialInfo={deviceInfo}>
              <Head>
                <CommonHead
                  cdnUrl={publicRuntimeConfig.cdnUrl}
                  analyticsOptions={{
                    enabled: isProduction,
                    googleTmId: 'GTM-PMDFG9',
                    userId,
                    theme,
                  }}
                />
              </Head>
              <AppAnalyticsPixel />
              <Seo />
              <div className={styles['wrapper']}>
                <TopBanner />
                <Header />
                <Component {...pageProps} />
              </div>
              <Footer
                menuLinks={sitesettings.footer.menu}
                socialLinks={sitesettings.footer.socialLinks}
                support={sitesettings.footer.support}
              />
            </DeviceInfoProvider>
          </ThemeProvider>
        </AbTestingProvider>
      </StoreProvider>
    </Interceptor>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: App.ReduxAppContext) => {
  let pageProps = {};
  let deviceInfo: IDeviceInfo = { phone: false, tablet: false, isAppleMobile: false };
  let theme: ThemeNames = ThemeName.lager;

  const isServer = typeof ctx.req !== 'undefined';
  const abTestingInfo = isServer && ctx.req.__AB_TESTING__ ? ctx.req.__AB_TESTING__ : undefined;

  const reduxStore = getOrCreateStore();
  ctx.store = reduxStore;

  if (typeof window === 'undefined') {
    if (ctx.req.__USER__) {
      reduxStore.dispatch(setUser(formatUserData(ctx.req.__USER__)));
    }
    if (ctx.req.__SITE_SETTINGS__) {
      reduxStore.dispatch(setSettings(ctx.req.__SITE_SETTINGS__));
    }
    if (ctx.req.__SELECTED_LOCATION__) {
      reduxStore.dispatch(setCurrentLocation(ctx.req.__SELECTED_LOCATION__));
    }
    if (ctx.req.__SEO__) {
      reduxStore.dispatch(setMetadata(ctx.req.__SEO__));
    }
    if (ctx.req && ctx.req.__DEVICE_INFO__) {
      deviceInfo = ctx.req.__DEVICE_INFO__;
    }
    if (ctx.req && ctx.req.__THEME__) {
      theme = ctx.req.__THEME__;
    }
    if (ctx.req && ctx.req.__DEPOSIT_RATES__) {
      reduxStore.dispatch(setDepositRates(ctx.req.__DEPOSIT_RATES__));
    }
    if (ctx.req && ctx.req.__MORTGAGE_RATES__) {
      reduxStore.dispatch(setMortgages(ctx.req.__MORTGAGE_RATES__));
    }
    if (ctx.req && ctx.req.__CREDIT_RATES__) {
      reduxStore.dispatch(setCreditRates(ctx.req.__CREDIT_RATES__));
    }
  }

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return {
    pageProps,
    initialReduxState: reduxStore.getState(),
    deviceInfo,
    realPath: ctx.req?.url || '',
    theme,
    abTestingInfo,
  };
};

export default MyApp;
