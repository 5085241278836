import { useSelector } from 'react-redux';

import { ILocationV2 } from '@sravni/types/lib/locations';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_LOCATION } from '../../constants/locations';
import { IGlobalState } from '../index';

export interface IState {
  currentLocation: ILocationV2;
}

const initialState: IState = {
  currentLocation: DEFAULT_LOCATION,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCurrentLocation: (state, { payload }: PayloadAction<ILocationV2>) => {
      state.currentLocation = payload;
    },
  },
});

const { actions, reducer } = locationSlice;

export const { setCurrentLocation } = actions;

export default reducer;

export const locationsSelector = (state: IGlobalState): IState => state.locations;
export const currentLocationSelector = (state: IGlobalState): IState['currentLocation'] =>
  locationsSelector(state).currentLocation;

export function useCurrentLocation(): IState['currentLocation'] {
  return useSelector<IGlobalState, IState['currentLocation']>(currentLocationSelector);
}
