import { ILocationV2 } from '@sravni/types/lib/locations';

export const DEFAULT_LOCATION: ILocationV2 = {
  garId: 1405113,
  name: 'Москва',
  fullName: 'г Москва',
  alias: 'moskva',
  routeGar: '1405113',
  localityType: 'город',
  shortLocationType: 'г',
  latitude: 55.7540471,
  longitude: 37.620405,
  regionRoute: '6.83.',
  fias: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
};
