import advertising from './advertising';
import creditRates from './creditRates';
import depositRates from './depositRates';
import locations from './locations';
import metadata from './metadata';
import mortgages from './mortgages';
import sitesettings from './sitesettings';
import user from './user';

export const reducers = {
  sitesettings,
  metadata,
  user,
  locations,
  advertising,
  mortgages,
  creditRates,
  depositRates,
};
