import getConfig from 'next/config';
import urlJoin from 'url-join';

import { CreditRatesDTO, CreditRateTermCodes } from '../interfaces/creditRates';
import client from '../utils/client';

export const getCreditRatesByTerm = async (term: CreditRateTermCodes) => {
  const url = urlJoin(getConfig().publicRuntimeConfig.apiPath, `/creditRates/${term}`);

  const resp = await client.get<CreditRatesDTO>(url);

  return resp;
};
