import dynamic from 'next/dynamic';
import React from 'react';

import { Header as DefaultHeader } from '@sravni/react-header';

import { useCurrentLocation } from '../../hooks/locations';
import { useSiteSettings } from '../../hooks/sitesettings';
import { useUser } from '../../hooks/users';

import styles from './header.module.scss';

const GlobalSearch = dynamic(() => import('@sravni/mf-search/GlobalSearch'), { ssr: false });

export const Header = () => {
  const settings = useSiteSettings();
  const user = useUser();
  const currentLocation = useCurrentLocation();

  return (
    <DefaultHeader
      className={styles.whiteBackground}
      currentRegion={currentLocation}
      settings={settings.header}
      user={user}
      search={<GlobalSearch />}
    />
  );
};
