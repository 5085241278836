import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { CreditRatesDTO, CreditRateTermCodes } from '../../interfaces/creditRates';
import { getCreditRatesByTerm } from '../../services/creditRates';

const initialState: CreditRatesDTO = [];
const sliceName = 'creditRates';

export const fetchCreditRates = createAsyncThunk<CreditRatesDTO, CreditRateTermCodes>(
  `${sliceName}/fetchCreditRates`,
  async (term) => {
    const { data } = await getCreditRatesByTerm(term);
    return data;
  },
);

const creditRatesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCreditRates: (_, action: PayloadAction<CreditRatesDTO>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreditRates.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

const { actions, reducer } = creditRatesSlice;

export const { setCreditRates } = actions;

export default reducer;
